export const da = {
  userRating: 'Brugernes bedømmelse',
  priceLegend: 'Pris pr. Person',
  button: 'Vælg',
  publicPrice: 'Offentlig Pris',
  unAvailableTitle: 'Opmærksomhed!',
  unAvailableText: 'Denne indkvartering vil snart være tilgængelig. Tilmeld dig Alert Service for at holde dig opdateret.',
  onRequest: 'Denne pakke er på forespørgsel',
  discountSaveText: 'Spar',
  sustainable: 'Bæredygtighedscertificeret',
  sunwebOnly: 'Sunweb Only'
}

export const de = {
  userRating: 'Benutzerbewertung',
  priceLegend: 'Preis pro Person',
  button: 'Wählen',
  publicPrice: 'Öffentlicher Preis',
  unAvailableTitle: 'Beachtung!',
  unAvailableText: 'Diese Unterkunft wird in Kürze verfügbar sein. Melden Sie sich beim Alert Service an, um immer auf dem neuesten Stand zu sein.',
  onRequest: 'Dieses Paket ist auf Anfrage',
  discountSaveText: 'Sparen',
  sustainable: 'Nachhaltig zertifiziert',
  sunwebOnly: 'Sunweb Only'
}

export const en = {
  userRating: 'User\'s rating',
  priceLegend: 'Price per person',
  button: 'Select',
  publicPrice: 'Public price',
  unAvailableTitle: 'Attention!',
  unAvailableText: 'This accommodation will be available soon. Sign up for the Alert Service to stay up to date.',
  onRequest: 'This package is on request',
  discountSaveText: 'Save',
  sustainable: 'Sustainability label',
  sunwebOnly: 'Sunweb Only'
}

export const nb = {
  userRating: 'Brukernes vurdering',
  priceLegend: 'Pris per person',
  button: 'Å velge',
  publicPrice: 'Openbare Prijs',
  unAvailableTitle: 'Merk følgende!',
  unAvailableText: 'Dette overnattingsstedet vil være tilgjengelig snart. Registrer deg for varslingstjenesten for å holde deg oppdatert.',
  onRequest: 'Denne pakken er på forespørsel',
  discountSaveText: 'Spar',
  sustainable: 'Sustainably certified',
  sunwebOnly: 'Sunweb Only'
}

export const fr = {
  userRating: 'Note de l\'utilisateur',
  priceLegend: 'Prix par personne',
  button: 'Prix par personne',
  publicPrice: 'Public Prix',
  unAvailableTitle: 'Attention!',
  unAvailableText: 'Cet hébergement sera disponible bientôt. Inscrivez-vous au service d\'alerte pour rester à jour.',
  onRequest: 'Ce forfait est sur demande',
  discountSaveText: 'Economisez',
  sustainable: 'Certifié durable',
  sunwebOnly: 'Exclu Sunweb'
}

export const nl = {
  userRating: 'Gebruikerswaardering',
  priceLegend: 'Prijs per persoon',
  button: 'Kiezen',
  publicPrice: 'Openbare Prijs',
  unAvailableTitle: 'Aandacht!',
  unAvailableText: 'Deze accommodatie zal binnenkort beschikbaar zijn. Meld u aan voor de Alert Service om op de hoogte te blijven.',
  onRequest: 'Dit pakket is op aanvraag',
  discountSaveText: 'Bespaar',
  sustainable: 'Duurzaam gecertificeerd',
  sunwebOnly: 'Sunweb Only'
}

export const sv = {
  userRating: 'Användarens betyg',
  priceLegend: 'Pris per person',
  button: 'Välj',
  publicPrice: 'Offentligt Pris',
  unAvailableTitle: 'Uppmärksamhet!',
  unAvailableText: 'Detta boende kommer snart att finnas tillgängligt. Anmäl dig för varningstjänsten att hålla dig uppdaterad.',
  onRequest: 'Detta paket är på begäran',
  discountSaveText: 'Spara',
  sustainable: 'Hållbarhetscertifierad',
  sunwebOnly: 'Sunweb Only'
}
